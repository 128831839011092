var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"-mt-16"},[_c('div',{staticClass:"flex sticky top-16 bg-blueGray-100 border-b px-6 pt-3"},[_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab,staticClass:"mx-3 font-semibold cursor-pointer",class:{
        'text-orange-600': tab == _vm.currentTab,
        'text-gray-400': tab != _vm.currentTab
      },on:{"click":function($event){_vm.currentTab = tab}}},[_vm._v(" "+_vm._s(_vm.$t(tab))+" "),(tab == _vm.currentTab)?_c('span',{staticClass:"border-orange-600 border-b-4 h-2 block",staticStyle:{"width":"60%","margin-left":"20%"}}):_vm._e()])}),_c('router-link',{staticClass:"absolute right-6 bg-emerald-500 text-emerald-50 shadow px-2 rounded cursor-pointer",attrs:{"to":{
        name: 'QrCodePrint',
        params: { type: _vm.currentTab },
        query: _vm.query
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("print all"))+" ")])],2),_c('div',{staticClass:"md:px-9 py-2 border-b sticky top-28 bg-blueGray-100",staticStyle:{"top":"6.8rem"}},[_c('el-select',{staticClass:"w-96",attrs:{"multiple":"","collapse-tags":"","placeholder":"Select hubs"},model:{value:(_vm.$store.state.selectedHubs),callback:function ($$v) {_vm.$set(_vm.$store.state, "selectedHubs", $$v)},expression:"$store.state.selectedHubs"}},_vm._l((_vm.$store.state.hubs.hubs),function(hub){return _c('el-option',{key:hub.id,attrs:{"label":hub.name,"value":hub.id}})}),1)],1),_c('div',{staticClass:"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-2 px-6 gap-2"},_vm._l((_vm.items),function(item){return _c('QrCode',{key:item.qr_id,attrs:{"item":item}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }