<template>
  <div class="-mt-16">
    <div class="flex sticky top-16 bg-blueGray-100 border-b px-6 pt-3">
      <div
        class="mx-3 font-semibold cursor-pointer"
        v-for="tab in tabs"
        @click="currentTab = tab"
        :key="tab"
        :class="{
          'text-orange-600': tab == currentTab,
          'text-gray-400': tab != currentTab
        }"
      >
        {{ $t(tab) }}
        <span
          v-if="tab == currentTab"
          class="border-orange-600 border-b-4 h-2 block"
          style="width: 60%; margin-left: 20%"
        />
      </div>

      <router-link
        class="absolute right-6 bg-emerald-500 text-emerald-50 shadow px-2 rounded cursor-pointer"
        :to="{
          name: 'QrCodePrint',
          params: { type: currentTab },
          query
        }"
        target="_blank"
      >
        {{ $t("print all") }}
      </router-link>
    </div>
    <div
      class="md:px-9 py-2 border-b sticky top-28 bg-blueGray-100"
      style="top: 6.8rem"
    >
      <el-select
        v-model="$store.state.selectedHubs"
        multiple
        class="w-96"
        collapse-tags
        placeholder="Select hubs"
      >
        <el-option
          v-for="hub in $store.state.hubs.hubs"
          :key="hub.id"
          :label="hub.name"
          :value="hub.id"
        >
        </el-option>
      </el-select>
    </div>
    <div
      class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mt-2 px-6 gap-2"
    >
      <QrCode v-for="item in items" :key="item.qr_id" :item="item" />
    </div>
  </div>
</template>

<script>
import QrCode from "@/components/QrCode.vue";
export default {
  name: "QRCodes",
  components: {
    QrCode
  },
  data() {
    return {
      currentTab: "assets",
      tabs: ["assets", "hubs", "Checklists"]
    };
  },
  computed: {
    query() {
      return { hubs: this.$store.state.selectedHubs.join(",") };
    },
    items() {
      const { bikes, hubs, checkLists, selectedHubs } = this.$store.state;
      if (this.currentTab == "assets")
        return bikes.bikes.filter(bike => {
          if (bike.hub_id == null) {
            return bike;
          }
          return selectedHubs.includes(bike.hub_id);
        });
      if (this.currentTab == "hubs")
        return hubs.hubs.filter(hub => {
          return selectedHubs.includes(hub.id);
        });
      if (this.currentTab == "Checklists") return checkLists.checkLists;
      return [];
    }
  },
  created() {
    // this.$store.dispatch("hubs/getAllHubs");
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
      this.$store.dispatch("bikes/getAllBikes");
      this.$store.dispatch("checkLists/getAllCheckLists");
    });
  }
};
</script>
